.passion-icon {
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;

  svg {
    width: 100%;
    height: 100%;
  }
}
