.navbar {
  $height: 60px;
  position: relative;
  flex: 0 0 auto;
  border: 1px solid #2f3847;
  border-bottom: 1px solid #848a92;
  height: $height;
  width: 100%;
  background-color: rgba(#0B1627, 0.9);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .navbar-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 100%;
    width: 100%;


    .navbar-logo {
      padding-left: 30px;
      justify-content: flex-start;
      width: 8 * (44 + 8);

      .logo {
        display: flex;
        width: 200px;
        height: 43px;
        position: relative;

        svg {
          flex: 1;
        }
      }
    }

    .navbar-title-text {
      text-transform: uppercase;
    }

    .navbar-menu {
      padding-right: 30px;
    }

    .application-logo-name {
      color: #fafbfc;
      font-size: 16px;
      font-family: 'cera-pro';
      font-weight: 700;
      position: relative;
      width: 250px;
      display: flex;
      align-items: center;
      margin-left: auto;
      flex-direction: row;
      justify-content: center;
      align-self: stretch;
      padding-right: 9px;
      padding-top: 2px;

      .divider {
        height: 20px;
        width: 1px;
        background-color: #fff;
        margin: 0 9px;
        transform: skew(-15deg);
      }

      .logo {
        display: flex;
        width: 200px;
        height: 43px;
        position: relative;

        svg {
          flex: 1;
        }
      }

      .name {
        text-transform: uppercase;
        position: relative;
        top: -2px;
      }
    }
  }
}


